import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
//import { useStaticQuery, graphql } from 'gatsby'

/*
const DeadLayout = ({ children, data }) => {

  const { site } = useStaticQuery(
    graphql`
      query bsSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `);
  return (
      <>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Upgrade your apartment building with next generation smart city technology to provide your community an unparalleled luxury living experience.' },
            { name: 'keywords', content: 'smart city, smart apartment, smart condominium, luxury living experience' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          {children}
        </div>
      </>
  )
}
*/

const Layout = ({ children, data }) => (
      <>
        <Helmet
          title={"Castellan"}
          meta={[
            { name: 'description', content: 'Upgrade your apartment building with next generation smart city technology to provide your community an unparalleled luxury living experience.' },
            { name: 'keywords', content: 'smart city, smart apartment, smart condominium, luxury living experience' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          {children}
        </div>
      </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

